exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-linktree-tsx": () => import("./../../../src/pages/linktree.tsx" /* webpackChunkName: "component---src-pages-linktree-tsx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-05-26-esse-vai-ser-meu-diario-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-05-26-esse-vai-ser-meu-diario.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-05-26-esse-vai-ser-meu-diario-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-05-27-alto-nivel-x-baixo-nivel-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-05-27-alto-nivel-x-baixo-nivel.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-05-27-alto-nivel-x-baixo-nivel-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-06-package-json-overrides-x-resolutions-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-06-package-json-overrides-x-resolutions.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-06-package-json-overrides-x-resolutions-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-19-modulo-1-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-19-modulo-1-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-19-modulo-1-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-20-como-se-tornar-um-senior-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-20-como-se-tornar-um-senior.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-20-como-se-tornar-um-senior-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-21-javascript-heap-out-of-memory-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-21-javascript-heap-out-of-memory.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-21-javascript-heap-out-of-memory-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-22-lib-forever-do-node-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-22-lib-forever-do-node.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-22-lib-forever-do-node-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-24-media-queries-com-tailwindcss-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-24-media-queries-com-tailwindcss.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-24-media-queries-com-tailwindcss-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-26-hot-n-code-trilha-frontend-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-26-hot-n-code-trilha-frontend.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-26-hot-n-code-trilha-frontend-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-27-hot-n-code-trilha-devops-and-cyber-security-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-27-hot-n-code-trilha-devops-and-cyber-security.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-27-hot-n-code-trilha-devops-and-cyber-security-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-28-hot-n-code-data-and-artificial-intelligence-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-28-hot-n-code-data-and-artificial-intelligence.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-28-hot-n-code-data-and-artificial-intelligence-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-29-hot-n-code-backend-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-06-29-hot-n-code-backend.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-06-29-hot-n-code-backend-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-03-modulo-2-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-07-03-modulo-2-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-03-modulo-2-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-13-modulo-2-aws-essentials-pt-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-07-13-modulo-2-aws-essentials-pt-2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-13-modulo-2-aws-essentials-pt-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-18-modulo-3-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-07-18-modulo-3-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-18-modulo-3-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-19-conectando-no-documentdb-com-mongoose-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-07-19-conectando-no-documentdb-com-mongoose.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-19-conectando-no-documentdb-com-mongoose-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-27-modulo-4-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-07-27-modulo-4-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-07-27-modulo-4-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-09-modulo-5-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-08-09-modulo-5-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-09-modulo-5-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-14-modulo-5-aws-essentials-pt-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-08-14-modulo-5-aws-essentials-pt-2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-14-modulo-5-aws-essentials-pt-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-27-entendendo-e-otimizando-aws-lambdas-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-08-27-entendendo-e-otimizando-aws-lambdas.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-27-entendendo-e-otimizando-aws-lambdas-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-30-modulo-6-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-08-30-modulo-6-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-08-30-modulo-6-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-10-universo-de-renderizacao-com-react-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-10-universo-de-renderizacao-com-react.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-10-universo-de-renderizacao-com-react-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-11-modulo-7-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-11-modulo-7-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-11-modulo-7-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-12-modulo-8-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-12-modulo-8-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-12-modulo-8-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-13-modulo-9-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-13-modulo-9-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-13-modulo-9-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-14-modulo-10-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-14-modulo-10-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-14-modulo-10-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-15-estudos-extras-aws-essentials-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-15-estudos-extras-aws-essentials.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-15-estudos-extras-aws-essentials-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-23-implementando-ssr-com-react-e-express-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-09-23-implementando-ssr-com-react-e-express.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-09-23-implementando-ssr-com-react-e-express-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-01-a-cultura-open-source-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-10-01-a-cultura-open-source.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-01-a-cultura-open-source-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-08-alterando-uma-lib-node-de-forma-rapida-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-10-08-alterando-uma-lib-node-de-forma-rapida.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-08-alterando-uma-lib-node-de-forma-rapida-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-22-iniciando-um-backoffice-rapidamente-com-adminjs-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-10-22-iniciando-um-backoffice-rapidamente-com-adminjs.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-22-iniciando-um-backoffice-rapidamente-com-adminjs-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-23-adminjs-diga-adeus-aos-templates-de-backoffice-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-10-23-adminjs-diga-adeus-aos-templates-de-backoffice.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-10-23-adminjs-diga-adeus-aos-templates-de-backoffice-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-05-entendendo-ecmascript-modules-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-11-05-entendendo-ecmascript-modules.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-05-entendendo-ecmascript-modules-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-06-ecmascript-modules-na-pratica-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-11-06-ecmascript-modules-na-pratica.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-06-ecmascript-modules-na-pratica-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-19-filas-e-eventos-a-engrenagem-dos-sistemas-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-11-19-filas-e-eventos-a-engrenagem-dos-sistemas.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-11-19-filas-e-eventos-a-engrenagem-dos-sistemas-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-12-11-entendendo-arquiteturas-de-sistema-com-c-4-model-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-12-11-entendendo-arquiteturas-de-sistema-com-c4-model.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-12-11-entendendo-arquiteturas-de-sistema-com-c-4-model-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2023-12-21-diagram-as-code-com-c-4-model-structurizr-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2023-12-21-diagram-as-code-com-c4-model-structurizr.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2023-12-21-diagram-as-code-com-c-4-model-structurizr-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-01-21-call-stack-eo-javascript-single-threaded-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-01-21-call-stack-eo-javascript-single-threaded.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-01-21-call-stack-eo-javascript-single-threaded-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-02-20-uma-volta-completa-no-event-loop-pt-1-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-02-20-uma-volta-completa-no-event-loop-pt-1.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-02-20-uma-volta-completa-no-event-loop-pt-1-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-03-11-uma-volta-completa-no-event-loop-pt-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-03-11-uma-volta-completa-no-event-loop-pt-2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-03-11-uma-volta-completa-no-event-loop-pt-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-03-24-testes-automatizados-na-teoria-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-03-24-testes-automatizados-na-teoria.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-03-24-testes-automatizados-na-teoria-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-10-14-introducao-ao-amazon-bedrock-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-10-14-introducao-ao-amazon-bedrock.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-10-14-introducao-ao-amazon-bedrock-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-10-22-componentes-de-uma-ia-generativa-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-10-22-componentes-de-uma-ia-generativa.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-10-22-componentes-de-uma-ia-generativa-mdx" */),
  "component---src-templates-post-tsx-content-file-path-static-posts-2024-11-06-tunando-sua-ia-llm-com-langchain-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/static/posts/2024-11-06-tunando-sua-ia-llm-com-langchain.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-static-posts-2024-11-06-tunando-sua-ia-llm-com-langchain-mdx" */)
}

